section#introduction {

    .navbar-wrap {
        background-color: #0d1037;
        max-height: 90px;

            &__inner {
                color: #fff;
                /* padding: 1.9em 1em; */
                max-width: 1200px;
                margin: 0 auto;
                display: flex;

                @media all and (min-width: 700px) and (max-width: 1100px) {
                    padding: 0em 3em;
                }

                @media all and (max-width: 700px) {
                    padding: 0em 3em;
                }


                .logo {
                    padding: .9em 0;
                    padding-left: 1.5em;
                    justify-content: flex-start;
                    flex: 1;
                    font-size: 1.6em;
                    /* font-family: 'Open Sans Condensed', sans-serif; */
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    letter-spacing: 1px;

                        a {
                            text-decoration: none;
                            color: #fff;
                        }


                    @media all and (min-width: 500px) and (max-width: 1100px) {
                        font-size: 1.5em;
                        padding: 1.1em 1.5em;
                    }

                    @media all and (max-width: 500px) {
                        font-size: 1.2em;
                        padding: 1.1em .5em;
                    }
                }

                .navbar-right {
                    justify-content: flex-end;


                    #menuToggle {
            
                        @media screen and (max-width: 785px) {
                            display: block;
                            top: 31px;
                            right: 50px;
                            z-index: 1;
                            position: absolute;
                            left: 0;
                            width: 100%;
                            height: 70px;
                            margin-top: 60px;
                            transform: translateX(-850px);
                            transition: transform 250ms ease-in-out;
                            background: crimson;
                        }

                        @media screen and (max-width: 500px) {
                            top: 11px;
                            height: 53px;

                        }
                    }
    
                    input[type="checkbox"]:checked ~ #menuToggle {
                        transform: translateX(0);
                    }
                    input[type=checkbox] {
                        transition: all 0.3s;
                        box-sizing: border-box;
                        display: none;
                    }
                    .sidebarIconToggle {
                        transition: all 0.3s;
                        box-sizing: border-box;
                        cursor: pointer;
                        position: absolute;
                        z-index: 99;
                        height: 100%;
                        width: 100%;
                        top: 32px;
                        right: 15px;
                        height: 24px;
                        width: 34px;

                        @media all and (min-width: 785px) {
                            display: none;
                        }

                        @media all and (max-width: 500px) {
                            top: 25px;

                        }
                    }
                    .spinner {
                        transition: all 0.3s;
                        box-sizing: border-box;
                        position: absolute;
                        height: 2px;
                        width: 100%;
                        background-color: #fff;

                        @media all and (max-width: 500px) {
                            height: 4px;
                        }
                    }
                    .horizontal {
                        transition: all 0.3s;
                        box-sizing: border-box;
                        position: relative;
                        float: left;
                        margin-top: 6px;
                    }
                    .diagonal.part-1 {
                        position: relative;
                        transition: all 0.3s;
                        box-sizing: border-box;
                        float: left;
                    }
                    .diagonal.part-2 {
                        transition: all 0.3s;
                        box-sizing: border-box;
                        position: relative;
                        float: left;
                        margin-top: 6px;
                    }
                    input[type=checkbox]:checked ~ .sidebarIconToggle > .horizontal {
                        transition: all .3s;
                        box-sizing: border-box;
                        opacity: 0;
                    }
                    input[type=checkbox]:checked ~ .sidebarIconToggle > .diagonal.part-1 {
                        transition: all .3s;
                        box-sizing: border-box;
                        transform: rotate(135deg);
                        margin-top: 8px;
                    }
                    input[type=checkbox]:checked ~ .sidebarIconToggle > .diagonal.part-2 {
                        transition: all .3s;
                        box-sizing: border-box;
                        transform: rotate(-135deg);
                        margin-top: -9px;

                        @media all and (max-width: 500px) {
                            transform: rotate(-134deg);
                            margin-top: -13px; 
                        }
                    }

                    ul#main-menu.right-nav {
                        margin-bottom: 0;
                        list-style: none;

                        li.nav-item {
                            display: inline-block;
                            padding: 1.9em 0;
                            transition: all .2s;

                            &:first-child {
                                @media all and (max-width: 785px) {
                                    margin-left: 0;
                                }
                            }

                            @media all and (max-width: 785px) {
                                list-style: none;
                                color: #fff;
                                text-transform: uppercase;
                                font-weight: bold;
                                padding: 21px 0;
                                cursor: pointer;
                                margin: 0em .3em;
                            }

                                &:hover {
                                    background: #fff;

                                    a {
                                        color: #0d1037;
                                    }
                                }

                            @media all and (max-width: 500px) {
                                padding: .3em 1em;
                                margin: 0;

                                &:hover {
                                    background: none;
                                }
                            }

                            @media all and (max-width: 400px) {
                                padding: .3em .1em;
                            }


                            a {
                                text-decoration: none;
                                color: #f7f7f7;
                                font-size: 1.2em;
                                font-family: 'Oswald', sans-serif;
                                padding: 1.5em 1.3em;

                                @media all and (max-width: 785px) {
                                   /*  color: #0d1037;
                                    font-weight: 100; */
                                }

                                @media all and (max-width: 500px) {
                                    font-size: 1em;
                                }
                            }
                        }
                        
                        @media all and (max-width: 785px) {
                            padding: 0 2.9em;
                        }

                        @media all and (max-width: 500px) {
                            padding: .5em 0em;
                        }
                    }

                   

                }

                
            }
    }

}