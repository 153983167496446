*,
*:before, *:after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.extra-pad {
    padding: 2em;
}

.sm-text-block {
    width: 80%;
}