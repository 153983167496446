#footer {
    background: #d1cbda;

    p {
        margin: 0;
        text-align: center;
        padding: 1em;
        color: #444;
        font-size: .7em;
    }
}