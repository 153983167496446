.portfolio {
    padding: 2em;
    background: #0D1037;

    .port-row {
        margin-bottom: 2em;
    }

    .port-column {
        position: relative;
        margin-bottom: 2em;

        .image-container {
            height: 461px;
            opacity: .9;
            position: relative;
    
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border: 9px solid white;
            }

            .hidden-content {
                position: absolute;
                width: 100%;
                top: 50%;
                transform: translateY(-50%);
                z-index: 12;
                padding: 2em;
                box-sizing: border-box;
                text-align: center;

                    h1, p {
                        margin: 0;
                        padding: 0;
                        color:#fff;
                        transition: 0.5s;
                        opacity: 0;
                        visibility: hidden;
                    }
                    .project-title {
                        font-family: 'Poppins', sans-serif;
                    }
                    .proj-description {
                        padding: 1em 0;
                        font-family: 'Poppins', sans-serif;
                    }
            }

            &:hover .hidden-content {

                h1, p {
                    opacity: 1;
                    visibility: visible;
                }
                p {
                    transition-delay: .3s;
                }
            }

            &:before {
                content: '';
                position: absolute;
                top: 10px;
                left: 10px;
                right: 10px;
                bottom: 10px;
                width: 97%;
                height: 96%;
                background: rgba(0,0,0,.7);
                transition: .5s;
                transform: scaleX(0);
                opacity: 0;
            }
            &:hover:before {
                transform: scaleX(1);
                opacity: 1;
            }

            &:after {
                content: '';
                position: absolute;
                top: 10px;
                left: 10px;
                right: 10px;
                bottom: 10px;
                transition: .5s;
                transform: scaleX(0);
                opacity: 0;
            }
            &:hover:after {
                transform: scaleY(1);
                opacity: 1;
            }
        }

        .image-overlay {
            display: flex;
            flex-wrap: wrap;
            position: absolute;
            top: 10.5%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 10;

            .buttons-wrap {


                button {
                    border: 0;
                    padding: .4em;
                    border-radius: 125px;
    
                    &.github-code {
                        height: 60px;
                        position: absolute;
                        background: white;
    
                        &:hover i.fab.fa-github {
                            color:#B5B2C1;
                        }
    
                        i.fab.fa-github {
                            font-size: 3rem;
                        }
                    }
    
                    &.goto-project {
                        border-radius: 0;
                        border: 0;
                        font-size: 1.3rem;
                        font-weight: 600;
                        display: block;
                        letter-spacing: .35em;
                        margin: 1em;
                        background: rgba(0,0,0,.7);
                        outline: 0;
                        background: rgba(250, 250, 0, 0.8);
                        font-family: 'Poppins', sans-serif;
                        color: #0D1037;


                        @media screen and (min-width: 992px) {
                            margin: 1em 1.35em;
                        }
                    }
                }

                a {

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
            
        }
    }

    
}

