.main-hero {
    background: #F3ECFF;
    background: linear-gradient(
       rgba(243, 236, 255, .7),
       rgba(243, 236, 255, .7)
   ),
   url('https://images.unsplash.com/photo-1461749280684-dccba630e2f6?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=750&q=80') no-repeat;
   background-size: cover; 
   background-attachment: fixed;
   height: 100vh;
   width: 100%;
   flex-wrap: wrap;
   /* z-index: 100; */
   position: relative;


   .main-title {
       position: absolute;
       top: 40%;
       left: 50%;
       transform: translate(-50%, -50%);

       @media all and (min-width: 700px) and (max-width: 1120px) {
           width: 80%;
       }

       @media all and (min-width: 500px) and (max-width: 700px) {
           font-size: 70%;
           width: 80%;

            h1 {
                font-size: 50%;
            }
       }

       @media all and (max-width: 500px) {
           font-size: 60%;
           width: 90%;

           h1 {
               margin: 0;
           }
       }

       &__descr {
           font-family: 'Oswald', sans-serif;
           font-size: 1.7em;
           display: flex;
           flex-wrap: wrap;
           width: 87%;

               .secondary-descr {
                   flex: 1 1 100%;
                   padding: .7em 0;
                   padding-bottom: .4em;
               }


           button.cta-resume, button.cta-work {
               background: #0D1037;
               padding: 1em;
               color: #fff;
               cursor: pointer;
               width: 50%;
               border: 0;
               flex: 1;
               margin-right: .3em;
               transition: all .2s;

                   &:hover {
                       background: #fff;
                       color: #0D1037;
                   }
           }

           h1.cta-resume__heading, h1.cta-work__heading {
               font-size: 1.7rem;
               color: #0D1037;

               @media all and (max-width: 785px) {
                   color: #fff;
               }

               @media all and (min-width: 500px) and (max-width: 700px) {
                   font-size: 1.1rem;
               }

               @media all and (max-width: 500px) {
                   font-size: 1rem;
               }
           }
           button.cta-resume {
               &:hover a {
                   color: #0D1037;
               }
           }
           h1.cta-resume__heading {
               color: #fff;

               div {

                a {
                   text-decoration: none;
                   color: #fff;
                }
                   
               }
           }

           button.cta-work {
               background: #F7DF1E;

               @media all and (max-width: 785px) {
                   background: crimson;
               }

               a {
                   text-decoration: none;

                   @media all and (max-width: 785px) {
                       color: #fff;
                   }
               }

               &:hover {
                   background:#fff;

                   h1 {
                       color: #0D1037;
                   }
               }
           }
       }

           h1.heading {
               font-size: 3rem;
               font-family: 'Oswald', sans-serif;
               font-weight: 700;
               color: #0D1037;
               text-shadow: 4px 3px 0px #FFFFFF;

               @media all and (min-width: 500px) and (max-width: 700px) {
                   font-size: 2.5rem;
               }

               @media all and (max-width: 500px) {
                   font-size: 2.1rem;
               }
           }
   }
}