section#secondary-intro {
    padding: 4em;

    @media all and (max-width: 700px) {
        padding: 3em 1em; 
    }

    p {
        font-family: 'Open Sans', sans-serif;
    }

    .skills-section {
        display:flex;
        padding: 2em;
        flex-wrap: wrap;


        @media all and (max-width: 700px) {
            width: 100%;
            display: unset;
        }

            h1 {
                font-family: 'Oswald', sans-serif;
                font-weight: 700;
                padding-bottom: .1em;
            }

        &__front-end {

            @media all and (min-width: 500px) and (max-width: 768px) {
                padding-bottom: 4em;
            }

            @media all and (max-width: 500px) {
                padding-bottom: 2em;
            }
        
            h1 {
            font-family: 'Oswald', sans-serif;
            font-weight: 700;
            padding-bottom: .1em;
        }
    }

        &__title {
            padding: 0;

            @media all and (min-width: 500px) and (max-width: 768px) {
                padding-left: .9em;
                padding-bottom: 2em;
            }
        }

        ul.skill-list-top, ul.skill-list-btm {
            list-style: none;
            display: flex;
        }

        ul.skill-list-top img, ul.skill-list-btm img {
            width: 70px;
            height: 70px;


            @media all and (min-width: 768px) and (max-width: 992px) {
                width:50px;
                height: 50px;
            }
        }

        ul.skill-list-top {

            li {
                float: left;

                &:not(:last-child) {
                    padding-right: 1em;
                }
            }
            
        }

        ul.skill-list-btm {

            li {
                float: left;

                &:not(:last-child) {
                    padding-right: 1em;
                }

            }
        }
    }

    .working-for {
        padding: 3em 1em;

        @media all and (min-width: 700px) and (max-width: 768px) {
            padding: 0em 1em;
        }

        @media all and (max-width: 700px) {
            padding: 0em 1em;
        }

            h1 {
                font-family: 'Oswald', sans-serif;
                font-weight: 700;
                padding-bottom: .1em;
            }

            h3 {
                font-family: 'Oswald', sans-serif; 
                padding-bottom: .2em;
            }

            ul.working-w-me {
                    list-style: none;

                    li {
                        padding: .2em 0;
                        font-style: italic;
                        font-weight: 600;
                    }
                
            }
    }
}

section#contact-me {
    padding: 2em;
    background: #0D1037;
    color: #fff;


    @media all and (max-width: 768px) {
        text-align: center;
    }

    h1 {
        font-family: 'Oswald', sans-serif;
        font-weight: 700;
        padding-bottom: .2em;
    }

    .contact-me__why, .contact-me__how h1 {
        color: #fff;
    }

    .contact-me__why {

        @media all and (max-width: 768px) {
            padding-bottom: 2em;
        }

        .contact-img-wrap {
            img {
                border-radius: 50%;
                border: 3px solid;
            }
        }

        span.info {
            display: inline-block;
            padding-top: 1em;
        }

    }

    .contact-me__how {
        padding-left: 4em;

        @media all and (max-width: 768px) {
            padding-left: unset;

            h1.intro, p.intro {
               text-align: center; 
            }
        }

        form.contact-me-form {

            @media all and (max-width: 768px) {
                text-align: left;
            }

            input {
                background: transparent;
                border: none;
                border-bottom: 1px solid #fff;
                width: 100%;
                outline: none;
                color: #fff;
                margin: .5em 0;
            }   

            textarea {
                overflow: auto;
                width: 100%;
                resize: vertical;
                background: none;
                border: 0;
                border-bottom: 1px solid #fff;
                outline: 0;
                color: #fff;
                height: 45px;
                transition: all .2s;

                &:focus {
                    height: 80px;
                }
            }

            input[type=submit] {
                width: 25%;
                float: right;
                padding: 1em;
                margin: 1em 0;
                transition: all .2s;

                &:hover {
                    background: #6b8e23;
                    border-bottom: 1px solid #6b8e23;
                }
            }
        }
    }
}